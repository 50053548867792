import React, { useState } from "react"
import { Form } from "react-bootstrap"

const RadioQuestion = ({
  myOnClick,
  hidden,
  disabled,
  data,
  checked,
  onChange,
}) => {
  return (
    <div>
      {data.map((value, index) => {
        return (
          <div className="borderMe2 space10px" key={index}>
            <Form.Check
              type="radio"
              label={value.label}
              id={value.id}
              checked={value.checked}
              onChange={onChange}
              onClick={() => {
                myOnClick(value.answer, value.label, value.id)
              }}
              name={value.name}
              disabled={disabled}
              hidden={hidden}
            />
            <p style={{ paddingLeft: "22px" }}>{value.text}</p>
          </div>
        )
      })}
    </div>
  )
}

export default RadioQuestion
