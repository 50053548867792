import React, { useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Row, Col, Form } from "react-bootstrap"
import graph2 from "../../images/topic-2/section-1/graph-2.svg"
import graph2_fr from "../../images/topic-2/section-1/graph-2_fr.svg"
import graph2b from "../../images/topic-2/section-1/graph-1.svg"
import graph3 from "../../images/topic-2/section-1/graph-3b.svg"
import cars from "../../images/topic-2/section-1/cars.jpg"
import cars_fr from "../../images/topic-2/section-1/cars_fr.jpg"
import video from "../../images/topic-2/section-1/fish.mp4"
import videob from "../../images/topic-2/section-1/fish.ogv"
import videoc from "../../images/topic-2/section-1/fish.webm"
import video2 from "../../images/topic-2/section-1/fish-curve.mp4"
import video2b from "../../images/topic-2/section-1/fish-curve.ogv"
import video2c from "../../images/topic-2/section-1/fish-curve.webm"
import fish from "../../images/topic-2/section-1/fish.jpg"
import fish_fr from "../../images/topic-2/section-1/fish_fr.jpg"
import equation from "../../images/equations/logistic-equation-1.svg"
import BottomNav from "../../components/bottomNav"
import Instructions from "../../components/instructions"
import TopHeader from "../../components/topHeader"
import MyBreadcrumb from "../../components/myBreadcrumb"
import RespMsg from "../../components/respMsg"
import RadioQuestion from "../../components/radioQuestion"
import parse from "html-react-parser"
import { Trans, useTranslation, useI18next } from "gatsby-plugin-react-i18next"
// =========================================================================================================
const LogGrowthSection1 = ({ location }) => {
  const { languages, originalPath, language } = useI18next()
  const { t } = useTranslation()

  const pageContent = {
    breadcrumb: parse(t("Topic2_Section1_breadcrumb[1]")),
    title: parse(t("Topic2_Section1_title")),
    topTexts: [
      //0
      <h4>{parse(t("Topic2_Section1_topText[0]"))}</h4>,
      //1
      <h4>{parse(t("Topic2_Section1_topText[1]"))}</h4>,
      //2
      <h4>{parse(t("Topic2_Section1_topText[2]"))}</h4>,
      //3
      <h4>{parse(t("Topic2_Section1_topText[3]"))}</h4>,
      //4
      <h4>{parse(t("Topic2_Section1_topText[4]"))}</h4>,
    ],
    bottomTexts: [
      //0
      parse(t("Topic2_Section1_bottomText[0]")),
      //1
      parse(t("Topic2_Section1_bottomText[1]")),
      //2
      parse(t("Topic2_Section1_bottomText[2]")),
      //3
      parse(t("Topic2_Section1_bottomText[3]")),
      //4
      parse(t("Topic2_Section1_bottomText[4]")),
    ],
    images: [
      {
        src: video,
        alt: parse(t("Topic2_Section1_images[0].alt")),
        text: parse(t("Topic2_Section1_images[0].caption")),
      },
      {
        src: video2,
        alt: parse(t("Topic2_Section1_images[1].alt")),
        text: parse(t("Topic2_Section1_images[1].caption")),
      },
      {
        src: "",
        alt: parse(t("Topic2_Section1_images[2].alt")),
        text: parse(t("Topic2_Section1_images[2].caption")),
      },
      {
        src: language == "en" ? graph2 : graph2_fr,
        alt: parse(t("Topic2_Section1_images[3].alt")),
        text: parse(t("Topic2_Section1_images[3].caption")),
      },
      {
        src: equation,
        alt: parse(t("Topic2_Section1_images[4].alt")),
        text: parse(t("Topic2_Section1_images[4].caption")),
      },
    ],

    topicsTitle: parse(t("Topic2_Section1_topicsTitle")),
    instructions: [
      parse(t("Topic2_Section1_instructions[0]")),
      parse(t("Topic2_Section1_instructions[1]")),
    ],
    radios: [
      {
        label: parse(t("Topic2_Section1.radios[0].label")),
        answer: false,
        msg: parse(t("Topic2_Section1.radios[0].msg")),
      },
      {
        label: parse(t("Topic2_Section1.radios[1].label")),
        answer: false,
        msg: parse(t("Topic2_Section1.radios[1].msg")),
      },
      {
        label: parse(t("Topic2_Section1.radios[2].label")),
        answer: true,
        msg: parse(t("Topic2_Section1.radios[2].msg")),
      },
    ],
    growthRates: [
      {
        text: parse(t("Topic2_Section1.growthRates[0].text")),
        image: language == "en" ? cars : cars_fr,
        alt: parse(t("Topic2_Section1.growthRates[0].alt")),
        caption: parse(t("Topic2_Section1.growthRates[0].caption")),
      },
      {
        text: parse(t("Topic2_Section1.growthRates[1].text")),
        image: language == "en" ? fish : fish_fr,
        alt: parse(t("Topic2_Section1.growthRates[1].alt")),
        caption: parse(t("Topic2_Section1.growthRates[1].caption")),
      },
      {
        text: parse(t("Topic2_Section1.growthRates[2].text")),
        image: graph2b,
        alt: parse(t("Topic2_Section1.growthRates[2].alt")),
        caption: parse(t("Topic2_Section1.growthRates[2].caption")),
      },
    ],
    stage5: {
      text: parse(t("Topic2_Section1.stage5.text")),
      image: graph3,
      alt: parse(t("Topic2_Section1.stage5.alt")),
      caption: parse(t("Topic2_Section1.stage5.caption")),
    },
  }
  //===============================================================================
  const [msgType, setMsgType] = useState("")
  const [msgContent, setMsgContent] = useState("")
  const [showMsg, setShowMsg] = useState(false)
  const [disableChoices, setDisableChoices] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
  const [stage, setStage] = useState(0)
  const [showRadioCheck, setShowRadioCheck] = useState(true)

  //=============================================================================================================================
  return (
    <Layout>
      <SEO title={parse(t("Topic2_Section1_pageTitle"))} />

      <MyBreadcrumb
        topicLink="logisticgrowth"
        topicTitle={parse(t("Topic2_Section1_breadcrumb[0]"))}
        currentTitle={pageContent.breadcrumb}
      />

      <div className="homeContent">
        <Row>
          <Col sm="8">
            <TopHeader
              title={pageContent.title}
              content={pageContent.topTexts[stage]}
            />
          </Col>
        </Row>
        <Instructions
          content={pageContent.instructions[stage]}
          hidden={stage != 0 && stage != 1}
        />
        <Row>
          <Col sm="12">
            <center>
              <img
                src={pageContent.images[stage].src}
                alt={pageContent.images[stage].alt}
                className="roundMe"
                hidden={(stage == 0) | (stage == 1)}
              />
              {/* first video */}
              <video
                width="100%"
                height="100%"
                controls
                controlsList="nofullscreen nodownload"
                // preload="none"
                className="myVideo"
                id="video1"
                hidden={stage != 0}
              >
                <source src={video} alt="" type="video/mp4" />
                <source src={videob} alt="" type="video/ogg" />
                <source src={videoc} alt="" type="video/webm" />
                {parse(t("Topic2_Section1_videoError"))}
              </video>
              {/* second video */}
              <video
                width="100%"
                height="100%"
                controls
                controlsList="nofullscreen nodownload"
                // preload="none"
                className="myVideo"
                id="video1"
                hidden={stage != 1}
              >
                <source src={video2} alt="" type="video/mp4" />
                <source src={video2b} alt="" type="video/ogg" />
                <source src={video2c} alt="" type="video/webm" />
                {parse(t("Topic2_Section1_videoError"))}
              </video>
              <p className="smText" style={{ maxWidth: "610px" }}>
                {pageContent.images[stage].text}
              </p>
            </center>
          </Col>
        </Row>
        <Row>
          <Col sm="12">{pageContent.bottomTexts[stage]}</Col>
        </Row>
        {/* STAGE 0 */}
        <div hidden={stage != 0}>
          <Form>
            <RadioQuestion
              data={[
                {
                  id: "radio1",
                  name: "formRadios",
                  label: pageContent.radios[0].label,
                  answer: pageContent.radios[0].answer,
                  checked: showRadioCheck == false ? 0 : null,
                },
                {
                  id: "radio2",
                  name: "formRadios",
                  label: pageContent.radios[1].label,
                  answer: pageContent.radios[1].answer,
                  checked: showRadioCheck == false ? 0 : null,
                },
                {
                  id: "radio3",
                  name: "formRadios",
                  label: pageContent.radios[2].label,
                  answer: pageContent.radios[2].answer,
                  checked: showRadioCheck == false ? 0 : null,
                },
              ]}
              // disabled={disableChoices == true}
              myOnClick={(answer, label, id) => {
                switch (id) {
                  case "radio1":
                    setMsgContent(pageContent.radios[0].msg)
                    break
                  case "radio2":
                    setMsgContent(pageContent.radios[1].msg)
                    break
                  case "radio3":
                    setMsgContent(pageContent.radios[2].msg)
                    break
                  default:
                    return null
                }
                if (answer == true) {
                  setMsgType("correct")
                } else {
                  setMsgType("error")
                }
                setShowMsg(true)
                setDisableChoices(answer)
                setNextBtnEnabled(answer)
              }}
              disabled={disableChoices}
            />
          </Form>
          <RespMsg type={msgType} content={msgContent} hidden={!showMsg} />
        </div>
        {/* STAGE 2 */}
        <div hidden={stage != 2}>
          <Row>
            <Col sm="6">{pageContent.growthRates[0].text}</Col>
            <Col sm="6">
              <img
                src={pageContent.growthRates[0].image}
                alt={pageContent.growthRates[0].alt}
              />
              <p className="smText">{pageContent.growthRates[0].caption}</p>
            </Col>
          </Row>
          <Row>
            <Col sm="6">{pageContent.growthRates[1].text}</Col>
            <Col sm="6">
              <img
                src={pageContent.growthRates[1].image}
                alt={pageContent.growthRates[1].alt}
              />
              <p className="smText">{pageContent.growthRates[1].caption}</p>
            </Col>
          </Row>
          <Row>
            <Col sm="6">{pageContent.growthRates[2].text}</Col>
            <Col sm="6">
              <img
                src={pageContent.growthRates[2].image}
                alt={pageContent.growthRates[2].alt}
              />
              <p className="smText">{pageContent.growthRates[2].caption}</p>
            </Col>
          </Row>
        </div>
        {/* STAGE 4 */}
        <div hidden={stage != 4}>
          <Row>
            <Col sm="6">{pageContent.stage5.text}</Col>
            <Col sm="6">
              <img
                src={pageContent.stage5.image}
                alt={pageContent.stage5.alt}
              />
              <p className="smText">{pageContent.stage5.caption}</p>
            </Col>
          </Row>
        </div>
        <hr />
        <Row>
          <Col sm="12" md="12" className="alignRight">
            <BottomNav
              onClickPrev={() => {
                switch (stage) {
                  case 1:
                    setStage(stage - 1)
                    setNextBtnEnabled(false)
                    setShowRadioCheck(true)
                    break
                  case 2:
                    setStage(stage - 1)
                    break
                  case 3:
                    setStage(stage - 1)
                    break
                  case 4:
                    setStage(stage - 1)
                    break
                  case 5:
                    setStage(stage - 1)
                    break
                  case 6:
                    setStage(stage - 1)
                    break
                  default:
                    return null
                }
              }}
              prevName={
                stage == 0 ? parse(t("Overview")) : parse(t("Previous"))
              }
              btnLinkPrev={stage == 0 ? "/logisticgrowth" : "#myHeader"}
              disabled={nextBtnEnabled == false}
              nextName={parse(t("Next"))}
              btnLinkNext={
                stage == 4 ? "/logisticgrowth/section2" : "#myHeader"
              }
              onClickNext={() => {
                setMsgType("")
                setMsgContent("")
                setShowMsg(false)
                setDisableChoices(false)
                switch (stage) {
                  case 0:
                    setStage(1)
                    setNextBtnEnabled(true)
                    setShowRadioCheck(false)
                    break
                  case 1:
                    setStage(2)
                    setNextBtnEnabled(true)
                    break
                  case 2:
                    setStage(3)
                    setNextBtnEnabled(true)
                    break
                  case 3:
                    setStage(4)
                    setNextBtnEnabled(true)
                    break
                  default:
                    return null
                }
              }}
            />
          </Col>
        </Row>
      </div>
      <br />
    </Layout>
  )
}

export default LogGrowthSection1
